var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading,"elevation":"2","outlined":""}},[_c('v-card-title',[_c('div',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[(!_vm.isMobileBrowser())?_c('v-text-field',{staticClass:"mr-3",staticStyle:{"max-width":"300px"},attrs:{"outlined":"","clearable":"","placeholder":"Search","rounded":"","solo":"","hide-details":"","color":"primary","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.searchbox),callback:function ($$v) {_vm.searchbox=$$v},expression:"searchbox"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","x-small":"","disabled":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.getData(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1)],1),(_vm.showLoadingComponent)?_c('loading-component'):_vm._e(),(_vm.isMobileBrowser())?_c('v-card-text',[_c('v-text-field',{staticClass:"mr-3",attrs:{"outlined":"","clearable":"","placeholder":"Search","rounded":"","solo":"","hide-details":"","color":"primary","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.searchbox),callback:function ($$v) {_vm.searchbox=$$v},expression:"searchbox"}})],1):_vm._e(),(_vm.hideTable == false)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","loading":_vm.loading,"loading-text":_vm.loadingText,"no-data-text":'Great!!!, no invoice due..',"headers":_vm.tableHeaders,"items":_vm.invoices,"search":_vm.searchbox,"item-key":"name"},scopedSlots:_vm._u([{key:"item.campaign.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},'span',attrs,false),on),[_vm._v(_vm._s(item.campaign.description))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.campaign.description))])])]}},{key:"item.no_",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.downloadPdf(item.no_)}}},[_vm._v(_vm._s(item.no_))])]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.appDateFormat(item.due_date)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberFormat(item.amount, 2)))]}}],null,true)}):_vm._e(),_c('invoice-detail',{ref:"myInvoiceDetail"}),(_vm.hideTable == true)?_c('v-card-text',[_c('div',{staticClass:"text-center py-6"},[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-thumb-up")]),_c('br'),_c('br'),_c('span',[_vm._v("Great!!!, no invoice due..")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }