<template>
  <v-card :loading="loading" elevation="2" outlined>
    <v-card-title>
      <div>{{ title }}</div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-text-field outlined v-model="searchbox" v-if="!isMobileBrowser()" clearable placeholder="Search" rounded solo hide-details="" color="primary" append-icon="mdi-magnify" dense style="max-width:300px" class="mr-3"></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" fab icon x-small :disabled="loading" color="primary" @click="getData(true)">
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <loading-component v-if="showLoadingComponent"></loading-component>

    <v-card-text v-if="isMobileBrowser()">
      <v-text-field outlined v-model="searchbox" clearable placeholder="Search" rounded solo hide-details="" color="primary" append-icon="mdi-magnify" dense class="mr-3"></v-text-field>
    </v-card-text>

    <v-data-table v-if="hideTable == false" dense :loading="loading" :loading-text="loadingText" :no-data-text="'Great!!!, no invoice due..'" :headers="tableHeaders" :items="invoices" :search="searchbox" item-key="name" class="elevation-1">
      <template v-slot:[`item.campaign.description`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 150px">{{ item.campaign.description }}</span>
          </template>
          <span>{{ item.campaign.description }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.no_`]="{ item }">
        <a href="javascript:;" @click="downloadPdf(item.no_)">{{ item.no_ }}</a>
      </template>
      <template v-slot:[`item.due_date`]="{ item }">{{ appDateFormat(item.due_date) }}</template>
      <template v-slot:[`item.amount`]="{ item }">{{ numberFormat(item.amount, 2) }}</template>
    </v-data-table>

    <invoice-detail ref="myInvoiceDetail"></invoice-detail>

    <v-card-text v-if="hideTable == true">
      <div class="text-center py-6">
        <v-icon large color="primary">mdi-thumb-up</v-icon>
        <br />
        <br />
        <span>Great!!!, no invoice due..</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import LoadingComponent from '../LoadingComponent.vue';
import InvoiceDetail from './InvoiceDetail.vue';

export default defineComponent({
  components: { LoadingComponent, InvoiceDetail },
  data: () => ({
    title: 'Invoice Due',
    showLoadingComponent: false,
    hideTable: false,
    loading: false,
    searchbox: '',
    loadingText: 'Loading data...',
    invoices: [],
    tableHeaders: [
      { text: 'Invoice No.', value: 'no_' },
      { text: 'Due Date', value: 'due_date' },
      { text: 'Contract No.', value: 'contract_no_' },
      { text: 'PO No.', value: 'po_no_' },
      {
        text: 'Campaign',
        align: 'start',
        value: 'campaign_description',
      },
      { text: 'Amount Excl VAT', value: 'amount', align: 'end' },
    ],
    dialog: null,
  }),
  methods: {
    async getData(refresh) {
      var uri = "invoice/due";
      if(refresh) this.AxiosStorageRemove("GET", uri);
      this.hideTable = false;
      this.loading = true;
      let conf = {
        cacheConfig: true,
      }
      await this.$axios
        .get(uri, conf)
        .then(res => {
          if (res.data.status=='success') {
            res = res.data;
            this.invoices = res.data.invoice;

            if (this.invoices.length == 0) {
              this.hideTable = true;
            }
          }
          this.loading = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$awn.alert('Internal Server Error');
          this.loading = false;
        })
    },

    async downloadPdf(ivNo) {
      let params = {
        no_: ivNo,
      }
      this.$store.state.showLoadingDialog = true
      await this.$axios
        .get('documents/invoice', {
          params: params,
          responseType: 'blob',
          cacheConfig: true,
        })
        .then(response => {
          this.$store.state.showLoadingDialog = false
          // console.log(response.data)

          if (response.data.type == 'application/json') {
            this.showAlert('warning', 'File Not Found!')
          } else if (response.data.type == 'application/octet-stream') {
            const file = new Blob([response.data], { type: 'application/pdf' })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            //Open the URL on new Window
            window.open(fileURL)
          } else {
            this.showAlert('warning', 'File Not Found!')
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$store.state.showLoadingDialog = false
          // console.log(error)
        })
    },
  },
  mounted() {
    this.getData()
  },
})
</script>