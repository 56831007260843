<template>
  <div>
    <page-title :title="$store.state.pageTitle" :breadcrumbs-items="breadcrumbsItems"></page-title>
    <v-row>
      <v-col cols="12" md="4">
        <dashboard-welcome></dashboard-welcome>
      </v-col>
      <v-col cols="12" md="12">
        <invoice-due></invoice-due>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardWelcome from '@/components/DashboardWelcome.vue'
import InvoiceDue from '@/components/invoice/InvoiceDue.vue'

export default {
  components: {
    DashboardWelcome,
    InvoiceDue,
  },
  data: () => ({
    
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/',
      },
    ],
  }),
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push('/login')
    }
  },
  mounted() {
    this.setTitle('Dashboard')
  },
}
</script>
