<template>
  <v-dialog v-model="dialog" max-width="1000" scrollable>
    <v-card flat tile>
      <v-toolbar flat elevation="0">
        <v-row dense>
          <v-col cols="12" sm="6" class="d-flex justify-start my-0">
            <span style="margin-top: 5px">Invoice #{{ invoiceDetailData.header.invoice_no_ }}</span>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-end my-0">
            <v-btn icon @click="dialog = false" small rounded>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-divider style="my-1"></v-divider>

      <v-card-text class="py-4">
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Invoice No.:</v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{ invoiceDetailData.header.invoice_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Invoice Date:</v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{
                appDateFormat(invoiceDetailData.header.posting_date)
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Your PO No.:</v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{ invoiceDetailData.header.po_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">PO Date:</v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{
                appDateFormat(invoiceDetailData.header.po_date)
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Contract No.:</v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{ invoiceDetailData.header.contract_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Contract Date:</v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{
                invoiceDetailData.header.contract_date != '0001-01-01 00:00:00'
                  ? appDateFormat(invoiceDetailData.header.contract_date)
                  : ' '
              }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="4" class="py-1 text-left">Project Name:</v-col>
              <v-col cols="8" class="py-1 text-left">{{ invoiceDetailData.campaign.description }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-1 text-left">Project No.:</v-col>
              <v-col cols="8" class="py-1 text-left">{{ invoiceDetailData.header.campaign_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-1 text-left">Order Type:</v-col>
              <v-col cols="8" class="py-1 text-left">{{ invoiceDetailData.header.order_type }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-1 text-left">Due Date:</v-col>
              <v-col cols="8" class="py-1 text-left">{{ appDateFormat(invoiceDetailData.header.due_date) }}</v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card class="my-4">
          <v-simple-table class="mb-3 my-0" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Item Description</th>
                  <th class="text-right">Quantity</th>
                  <th class="text-left">UoM</th>
                  <th class="text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in detailData" :key="key">
                  <td class="py-0 text-left">{{ item.item_description }}</td>
                  <td class="py-0 text-right">{{ numberFormat(item.quantity) }}</td>
                  <td class="py-0 text-left">{{ item.uom }}</td>
                  <td class="py-0 text-right">{{ numberFormat(item.amount, 2) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-divider></v-divider>
        <v-row class="my-4">
          <v-col cols="12" sm="6"> </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="6" class="py-1 text-left font-weight-bold">Total:</v-col>
              <v-col cols="6" class="py-1 text-right font-weight-bold">Rp. {{
                numberFormat(invoiceDetailData.header.amount, 2)
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-1 text-left font-weight-bold">PPN:</v-col>
              <v-col cols="6" class="py-1 text-right font-weight-bold">Rp. {{
                numberFormat(invoiceDetailData.header.amount_including_vat - invoiceDetailData.header.amount, 2)
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-1 text-left font-weight-bold">Nett Total:</v-col>
              <v-col cols="6" class="py-1 text-right font-weight-bold">Rp. {{
                numberFormat(invoiceDetailData.header.amount_including_vat, 2)
              }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="downloadPdf()" small text><v-icon>mdi-download</v-icon> Download Document</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false" small>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    dialog: null,
    invoiceDetailData: {
      header: {},
      detail: {},
      campaign: {},
    },
  }),

  computed: {
    detailData: function(){
      return this.invoiceDetailData.detail
    },
  },

  methods: {
    async getData(invoice_no_) {
      this.dialog = false
      this.$store.state.showLoadingDialog = true
      var params = {
        invoice_no_: invoice_no_,
      }
      await this.$axios
        .get('campaign/detail/invoicing-detail', {
          params: params,
          cacheConfig: true,
        })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.invoiceDetailData.header = resData.data.header
              this.invoiceDetailData.detail = resData.data.detail
              this.invoiceDetailData.campaign = resData.data.campaign

              this.dialog = true
            }
          } else {
            this.$awn.alert(resData.message)
          }
          this.$store.state.showLoadingDialog = false
        })
        .catch(error => {
          if (error.response) {
            this.$awn.alert('Internal Server Error')
            console.log(error.response.headers)
          } else {
            console.log('Error', error.message)
          }
          this.$store.state.showLoadingDialog = false
        })
    },

    async downloadPdf() {
      let ivNo = this.invoiceDetailData.header.invoice_no_
      let params = {
        no_: ivNo,
      }
      this.$store.state.showLoadingDialog = true
      await this.$axios
        .get('documents/invoice', {
          params: params,
          responseType: 'blob',
          cacheConfig: true,
        })
        .then(response => {
          this.$store.state.showLoadingDialog = false
          console.log(response.data)
          if (response.data.type == 'application/json') {
            this.$awn.alert('File not found.')
          } else if (response.data.type == 'application/octet-stream') {
            const file = new Blob([response.data], { type: 'application/pdf' })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            //Open the URL on new Window
            window.open(fileURL)
          } else {
            this.$awn.alert('File not found.')
          }
        })
        .catch(error => {
          this.$store.state.showLoadingDialog = false
          console.log(error)
        })
    },
  },
}
</script>