<template>
  <v-card class="greeting-card">


    <v-row class="ma-0 pa-0">
      <v-col cols="8">
        <v-card-title class="text-no-wrap pt-1 ps-2"> Welcome Customer! 🥳 </v-card-title>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <loading-component v-if="showLoadingComponent"></loading-component>
          <div v-if="showLoadingComponent == false">
            <p class="text-xl font-weight-semibold primary--text mb-2">{{ campaigns.length }} Campaigns</p>

            <v-btn small color="primary" link :to="{ name: 'Campaign' }"> Your Campaign </v-btn>
          </div>
        </v-card-text>
      </v-col>

      <v-col cols="4">
        <v-img
          contain
          height="180"
          width="159"
          :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
          class="greeting-card-bg"
        ></v-img>
        <v-img
          contain
          height="108"
          max-width="83"
          class="greeting-card-trophy"
          src="@/assets/images/misc/trophy.png"
        ></v-img>
      </v-col>
    </v-row>
    
    
  </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import LoadingComponent from './LoadingComponent.vue'

export default defineComponent({
  components:{ LoadingComponent },
  data: () => ({
    campaigns: [],
    showLoadingComponent: false,
  }),
  methods: {
    async getData() {
      this.showLoadingComponent = true
      await this.$axios
        .get('/campaign/data', {
          cacheConfig: true,
        })
        .then(res => {
          if (res) {
            res = res.data
            this.campaigns = res.data
          }
          this.showLoadingComponent = false
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data)
            // console.log(error.response.status)
            // console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message)
          }
          // console.log(error.config)
          this.$awn.alert('Internal Server Error')
          this.showLoadingComponent = false
        })
    },
  },
  mounted() {
    this.getData()
  },
})
</script>


<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
